import './Hero.css';
import Section from '../Section/Section';
import ProfileImage from '../../../images/profile_img.jpg';
import PrimaryCTA from '../../atoms/CTA/PrimaryCTA';
import Underscore from '../../atoms/Underscore/Underscore';

const Hero = () => {
  return (
    <Section className="hero-section" id="home">
      <div className="row-wrapper">
        <div className="col col-left">
          <h1>Front End Web Developer<Underscore /></h1>
          <div className="spacer-sm"></div>
          <div className="text-container">
            <p>Hi, I'm Kevin! A Front-End Wed Developer with a passion for writing semantic, organized code while implementing attractive and creative designs.</p>
            <div className="spacer-md"></div>
            <div className="cta-btns">
                <PrimaryCTA />
                {/* <SecondaryCTA /> */}
            </div>
          </div>
        </div>
        <div className="col col-right">
          <div className="svg-blob">
            <svg width="559" height="562" viewBox="0 0 559 562" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M282.002 12.9098C356.545 6.38975 440.173 -19.7206 497.375 28.519C556.495 78.377 563.24 166.904 555.896 243.891C549.245 313.618 506.436 369.436 459.866 421.755C409.13 478.752 357.097 538.041 282.002 551.589C192.895 567.665 82.5698 570.626 25.8342 500.06C-29.8199 430.838 19.5001 331.416 34.6136 243.891C45.9717 178.115 52.4861 108.719 101.276 63.165C148.788 18.8042 217.248 18.5738 282.002 12.9098Z" fill="#EE916C"/>
            </svg>
          </div>
          <img className="circle-img" src={ ProfileImage } alt="Kevin Dailey Profile" />
        </div>
      </div>
    </Section>
  );
}

export default Hero;