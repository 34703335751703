import './Contact.css'
import Section from '../Section/Section';
import Underscore from '../../atoms/Underscore/Underscore';
import PrimaryCTA from '../../atoms/CTA/PrimaryCTA';


const Contact = () => {
  return (
    <Section className="contact-section" id="contact">
      <div className="row-wrapper">
        <div className="col">
          <h2>Send an Email My Way<Underscore /></h2>
          <div className="spacer-sm"></div>
          <p>Have a question or comment? Feel free to send me an email using the link below. Thank you!</p>
          <div className="spacer-md"></div>
          <PrimaryCTA />
        </div>
      </div>
    </Section>
  );
}

export default Contact;