import logo from '../../../images/logo.svg';
import './Header.css'
import { useState } from 'react';


const Header = () => {
    const [ isMobileNavOpen, setIsMobileNavOpen ] = useState( false );

    function toggleNav() {
      if( isMobileNavOpen ) {
        document.body.style.overflow = 'visible';
      } else {
        document.body.style.overflow = 'hidden';
      }
      setIsMobileNavOpen( !isMobileNavOpen );

    }

    return (
      <header className="header">
        <div className="container">
          <div className="logo-wrapper">
            <img src={ logo } alt="Kevin Dailey Logo" />
          </div>
          <div className="nav-wrapper">
            <nav className="desktop-nav">
              <ul>
                <li><a href="#home">Home</a></li>
                <li><a href="#skills">About</a></li>
                {/* <li><a href="#projects">Projects</a></li> */}
                <li><a href="#contact">Contact</a></li>
              </ul>
            </nav>
            <nav className={ `mobile-nav ${ isMobileNavOpen ? 'open' : '' }` }>
              <ul>
                <li><a href="#home" onClick={ toggleNav }>Home</a></li>
                <li><a href="#skills" onClick={ toggleNav }>About</a></li>
                {/* <li><a href="#projects" onClick={ toggleNav }>Projects</a></li> */}
                <li><a href="#contact" onClick={ toggleNav }>Contact</a></li>
              </ul>
            </nav>
            <button 
              className={ `mobile-nav-toggle  ${ isMobileNavOpen ? 'active' : '' }` } 
              onClick={ toggleNav }
            >
              <span className="hamburger-line top-line"></span>
              <span className="hamburger-line center-line"></span>
              <span className="hamburger-line bottom-line"></span>
            </button>
          </div>
        </div>
      </header>
    );
}

export default Header;