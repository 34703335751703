import './Skills.css'
import Section from '../Section/Section';
import Underscore from '../../atoms/Underscore/Underscore';
import SkillBoxes from '../../molecules/SkillsBoxes/SkillBoxes';

const Skills = () => {
  return (
    <Section className="skills-section" id="skills">
      <div className="row-wrapper">
        <div className="col col-left">
          <SkillBoxes />
        </div>
        <div className="col col-right mobile-primary">
          <h1>My Stack<Underscore /></h1>
          <div className="spacer-sm"></div>
          <p>Here are some of the web technologies that I have used and are most familiar with.</p>
        </div>
      </div>
    </Section>
  );
}

export default Skills;