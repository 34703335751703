import './SkillBoxes.css';

const SkillBoxes = () => {
  return  (
    <ul className="skill-boxes">
      <li className="skill-box">
        <figure className="skill-icon"><i class="devicon-html5-plain"></i></figure>
        <p className="skill-label">HTML</p>
      </li>
      <li className="skill-box">
        <figure className="skill-icon"><i class="devicon-css3-plain"></i></figure>
        <p className="skill-label">CSS</p>
      </li>
      <li className="skill-box">
        <figure className="skill-icon"><i class="devicon-sass-original"></i></figure>
        <p className="skill-label">SASS</p>
      </li>
      <li className="skill-box">
        <figure className="skill-icon"><i class="devicon-javascript-plain"></i></figure>
        <p className="skill-label">JS</p>
      </li>
      <li className="skill-box">
        <figure className="skill-icon"><i class="devicon-jquery-plain"></i></figure>
        <p className="skill-label">JQuery</p>
      </li>
      <li className="skill-box">
        <figure className="skill-icon"><i class="devicon-react-original"></i></figure>
        <p className="skill-label">React</p>
      </li>
    </ul>
  );
}

export default SkillBoxes;