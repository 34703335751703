import './Section.css';

const Section = ( props ) => {
  return (
    <section 
      className={`site-section ${ props.className ? props.className : '' }`}
      id={ props.id ? props.id : '' }
    >
      <div className="container">
        { props.children }
      </div>
    </section>
  )
}

export default Section;