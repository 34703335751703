import './Footer.css';

const Footer = () => {
    return (
      <footer className="footer">
        <div className="container footer-container">
          <p>Designed by Kevin Dailey</p>
          <div className="footer-socials">

          </div>
        </div>
      </footer>
    );
}

export default Footer;