import './App.css';
import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';
import Hero from '../../organisms/Hero/Hero';
import Skills from '../../organisms/Skills/Skills';
import Contact from '../../organisms/Contact/Contact';


const App = () => {
  return (
    <>
      <Header />
      <div className="main-container">

        <Hero />

        <Skills />

        {/* <Projects /> */}
        
        <Contact />

      </div>
      <Footer />
    </>
  );
}

export default App;
